import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";

import {I18n} from "PNH/components/I18n";


class TransactionsView extends React.Component {
    renderTransaction = () => {
        const {classes} = this.props;
        const transaction = this.props.activeTransaction;
        const activeTransaction = _.cloneDeep(transaction)
        if(transaction.value > 0) {
            activeTransaction.items.push({
                value: transaction.value,
                item_type: "transaction_online_transfer_value",
                user: transaction.user,
                supplement: "0.00 ",
                payment_method: "pay_by_online_transfer"
            })
        }

        return (
            <>
                <h4>
                    Transakcja <b>#{activeTransaction.identifier}</b>
                    <h6>{activeTransaction.created_at}</h6>
                </h4>

                <GridContainer style={{fontSize: 12}}>
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>Informacje</h6>
                                <GridContainer justify="space-beetwen">
                                    <GridItem xs={6}>Płatność:</GridItem>
                                    <GridItem xs={6}>
                                        {I18n.t(activeTransaction.payment_method)}
                                    </GridItem>
                                    <GridItem xs={6}>Status:</GridItem>
                                    <GridItem xs={6}>
                                        {I18n.t(activeTransaction.status)}
                                        {activeTransaction.payment_method === "online_transfer" &&
                                            activeTransaction.status === "unpaid" && (
                                                <a
                                                    href={`/dotpay/transactions/${activeTransaction.id}`}
                                                >
                                                    {" "}
                                                    - Zapłać
                                                </a>
                                            )}
                                    </GridItem>

                                    <GridItem xs={6}>Typ: </GridItem>
                                    <GridItem xs={6}>
                                        {I18n.t(activeTransaction.transaction_type)}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>
                                    Wykupione zajęcia i usługi
                                </h6>
                                <Table
                                    striped
                                    tableHead={[
                                        <b style={{fontSize: 12}}>Użytkownik</b>,
                                        <b style={{fontSize: 12}}>Typ</b>,
                                        <b style={{fontSize: 12}}>Wartość</b>,
                                        <b style={{fontSize: 12}}>Dopłata</b>,
                                        <b style={{fontSize: 12}}>Płatność</b>,
                                        <b style={{fontSize: 12}}>Kod</b>,
                                    ]}
                                    tableData={
                                        activeTransaction !== false
                                            ? activeTransaction.items.map((row) => {
                                                return [
                                                    <a href={`/users/${row.user.id}`}> {`${row.user.first_name} ${row.user.last_name}`}</a>,
                                                    row.item_type === "buy_activity" ||
                                                    row.item_type === "resign_activity" ? (
                                                        <>
                                                            {I18n.t(row.item_type)}
                                                            <br/>
                                                            <a
                                                                href={`/activities/${row.targetable.id}`}
                                                            >
                                                                #{row.targetable.id} -
                                                                {row.targetable.label}
                                                            </a>
                                                        </>
                                                    ) : (
                                                        I18n.t(row.item_type)
                                                    ),
                                                    ["benefit", "ok system"].includes(
                                                        row.payment_method
                                                    )
                                                        ? "0 PLN"
                                                        : row.value + "PLN",
                                                    ,
                                                    row.supplement + "PLN",
                                                    row.item_type === "buy_activity" ||  row.item_type === "transaction_online_transfer_value"
                                                        ? I18n.t(row.payment_method)
                                                        : "-",
                                                    row.transaction_item_promo_code ? row.transaction_item_promo_code.code : null
                                                ];
                                            })
                                            : []
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>Stan Konta Przed:</h6>
                                <b>Główne:</b> {activeTransaction.account_balance_before}zł,{" "}
                                <b>Bonus:</b> {activeTransaction.account_bonus_before}zł,{" "}
                                <b>Kaucja:</b> {activeTransaction.account_deposit_before}zł
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>Stan Konta Po:</h6>
                                <b>Główne:</b> {activeTransaction.account_balance_after}zł,{" "}
                                <b>Bonus:</b> {activeTransaction.account_bonus_after}zł,{" "}
                                <b>Kaucja:</b> {activeTransaction.account_deposit_after}zł
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>
                                    Komentarze do transakcji:
                                    <hr/>
                                </h6>
                                {activeTransaction.comments.map((row) => {
                                    return (
                                        <div key={row.id}>
                                            <p>
                                                <b>{` ${row.user.first_name} ${row.user.last_name}`}</b>{" "}
                                                <Primary>
                                                    <b style={{fontSize: 10}}>{row.created_at} </b>
                                                </Primary>
                                            </p>

                                            {row.comment}
                                            <hr/>
                                        </div>
                                    );
                                })}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        );
    };


    render() {
        return (
            <>
                {this.renderTransaction()}
            </>
        );
    }
}

TransactionsView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles({...modalStyles, ...extendedTablesStyle})(
    TransactionsView
);
