import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import CardFooter from "../../../../components/Card/CardFooter";
import Button from "../../../../components/CustomButtons/Button";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import moment from "moment/moment";
import React, {useCallback, useState} from "react";
import formRegular from "../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "../../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsModal from "../../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import {Api} from "../../../services/Api";
import ImageUpload from "../../../components/CustomUpload/ImageUpload";

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};

const MonthlyCore = ({classes, ...props}) => {
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    const [csv, setCsv] = useState()


    const generateReport = useCallback(() => {
        console.log("report")
        Api.reports.admin.create({
            async_job: {
                job_class: "Reports::ImportBenefitReportService",
                params: {csv_file: csv}
            }
        }).then((r) => {
            if(r.ok) {
                window.setNotifications([
                    {type: "success", msg: "Dodano do kolejki"},
                ]);
            }
        })
    })

    return <Card>
        <CardHeader>
            <h3>Benefit importer</h3>

        </CardHeader>
        <CardBody>
            <GridContainer>
                <GridItem xs={12}>
                    <>
                        <ImageUpload
                            file
                            value={csv}
                            onChange={setCsv}
                        />
                    </>
                </GridItem>
            </GridContainer>
        </CardBody>
        <CardFooter>
            <Button color={"primary"} fullWidth round onClick={generateReport}>Gneruj</Button>
        </CardFooter>
    </Card>
}

export default withStyles(styles)(MonthlyCore);