import React from "react";
import { withRouter } from "react-router-dom";
import { FiltersForm } from "./Search";
import {currentUrlParams} from 'PNH/services/Helpers'


class Transactions extends React.Component {
  render() {
    const url = currentUrlParams().urlPath;
    

      return (
          <FiltersForm {...this.props} />
      );

  }
}

Transactions.propTypes = {};

export default withRouter(Transactions);
