import Danger from "components/Typography/Danger";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import { I18n } from "PNH/components/I18n";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import Primary from "../../../../components/Typography/Primary";
import {FiltersForm} from "../../AsyncJobs/Search";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Modal from "../../../components/Modal";
import TransactionsView from "../View";

export const  RowFunction = (row, formik) => {
    const { classes, values, setFieldValue } = formik;
    return [
        row.identifier,
        row.created_at,
        row.transaction_type === "buy_activity" ||
        row.transaction_type === "resign_activity" ? (
            <>
                {I18n.t(row.transaction_type)}
                <br />
                {row.items.map((item) => {
                    if (!item.targetable_id) {
                        return null;
                    }
                    return (
                        <a href={`/activities/${item.targetable.id}`}>
                            #{item.targetable.id},{" "}
                        </a>
                    );
                })}
            </>
        ) : (
            I18n.t(row.transaction_type)
        ),
        I18n.t(row.payment_method),
        row.status === "paid" || row.status === "canceled" ? (
            <Primary>
                <b>{I18n.t(row.status)}</b>
            </Primary>
        ) : (
            <Danger>
                <b>Nieopłacone</b>
            </Danger>
        ),

        <Modal modalBody={<TransactionsView activeTransaction={row}  {...formik} />}  modalSize={'xl'} modalButtonText={<AssignmentIcon />} buttonProps={{justIcon: true, simple: true, color: 'transparent'}}/>,
    ];
} 