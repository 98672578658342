import { withFormik } from "formik";
import validationSchema from "../../Transactions/Search/ValidationSchema";
import ActivitiesTable from "PNH/components/Filters/Table"
import { Api, serializeParams } from "PNH/services/Api";
import { withRouter } from 'react-router-dom';
import { RowFunction } from "../../Transactions/Search/RowFunction";
import { TableFilters } from "../../Transactions/Search/Filters";
import moment from "moment";

export const SearchFunctions = {
  index: (values, formik) => {

    return Api.transactions.admin
        .index({ ...values, ...{ data: [], search: true } })
        .then((response) => {
          if (response.ok) {
            formik.setFieldValue("data", response.data);
            formik.setFieldValue("total", response.data.total);
          }
        });
  },

  getData: async (formik) => {
    SearchFunctions.index(formik.values, formik);
  },

  changePage: async (page, formik) => {
    let pageChange = await formik.setFieldValue('page', page)
    SearchFunctions.index({...formik.values, ...{page: page} }, formik);
  },

  mapRows: (row, formik) => {
    return RowFunction(row, formik)
  },

  clearFilters: () => {
    window.location.href = "/transactions";
  },

  onChangeRowsPerPage: async (e, formik) => {
    let limit = await formik.setFieldValue('limit', e.target.value);
    let page = await formik.setFieldValue('page', 0)
    SearchFunctions.getData(formik)
  },

  handleAddButton: (formik) => {},

  bulkUpdateComponent: (formik) => {},

  filtersComponent: (formik) => {
    return TableFilters(formik)
  },

  tableHeaders: () => [
    "Nr Transakcji",
    "Data",
    "Typ",
    "Płatność",
    "Status",
    "",
  ],

  showAddButton: () => false,
  showClearButton: () => true,

  orderBy: () => [
    "transactions.created_at asc",
    "transactions.created_at desc",
  ],

  defaultValues: (props) => {
    const urlParams = new URLSearchParams(props.location.search);
    return {
      page: parseInt(urlParams.get("page")) || 0,
      limit: 25,
      data: { rows: [] },
      activitiesIDs: [],
      total: 0,
      with_user_id: props.userID,
      with_id: urlParams.get("with_id") || "",
      with_created_at_less: urlParams.get("with_created_at_less") || "",
      with_created_at_greater: urlParams.get("with_created_at_greater") || "",
      with_identifier: urlParams.get("with_identifier") || "",

      order: urlParams.get("order") || "transactions.date asc",
      afterSave: props.afterSave,
    };
  },
};

export const TransactionsTable = withRouter(withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...SearchFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    const tmp = {...values, ...{page: 0}}
    formikProps.setSubmitting(true);

    SearchFunctions.index(tmp, formikProps)
        .then((response) => { })
        .then(() => {
          formikProps.setFieldValue("page", 0);
          formikProps.setTouched({});
        });
  },
})(({ ...props }) => <ActivitiesTable SearchFunctions={SearchFunctions} {...props} />));
