/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, {useState, useEffect, useCallback} from "react";
import _, {debounce} from "lodash"
import {Router, Route, Switch, Redirect} from "react-router-dom";
import {createBrowserHistory} from "history";
import AuthLayout from "PNH/layouts/LoginPage";
import AdminLayout from "PNH/layouts/Admin";
import MainContainer from "./layouts/MainContainer";
import NotFound from "./layouts/NotFound";
import 'moment/locale/pl'
import {Api} from "./services/Api";
import {NotificationUpdater} from "./services/NotificationUpdater";

var hist = createBrowserHistory();
var currentUser = null;
var stateUpdates = {}

const INTERVAL_TIME = 60*60*1000;

const events = [
    "mousemove",
    "wheel",
    "keydown",
    "touchstart",
];



const Application = () => {
    const [fetch, setFetch] = useState(0)

    const callCurrentUser = useCallback(() => {
        const la = localStorage.getItem('last_activity')
        if(!la || Date.now() - la  < INTERVAL_TIME) {
            return Api.users.show("current").then((r) => {
                if (r.ok && r.data.id) {
                    localStorage.setItem('user', JSON.stringify(r.data));
                    localStorage.setItem('token', r.headers.authorization);
                }
                return r
            })
        } else {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('last_activity');
            window.location.reload()
        }
    })

    useEffect((r) => {
        if (localStorage.getItem('token') && !currentUser) {
            callCurrentUser().then(r => {
                if (r.ok && r.data.id) {
                    currentUser = r.data
                    setFetch(fetch + 1)
                }
            })
        }
    }, [])

    useEffect(() => {
        localStorage.removeItem('last_activity');
        events.forEach((event) =>
            window.addEventListener(event, resetTimer, { passive: true })
        );

        return () => {
            events.forEach((event) =>
                window.removeEventListener(event, resetTimer, { passive: true })
            );
        };
    }, []);

    const resetTimer = () => {
        localStorage.setItem('last_activity', Date.now())
    }


    const isLoggedIn = () => {
        return !(localStorage.getItem('token') === null)
    }

    const getStateUpdates = () => stateUpdates
    const getCurrentUser = () => currentUser
    const setCurrentUser = (user) => {
        currentUser = user
    }

    return <MainContainer>
        {isLoggedIn() && <NotificationUpdater getCurrentUser={getCurrentUser} getStateUpdates={getStateUpdates}
                                              setCurrentUser={setCurrentUser} callCurrentUser={callCurrentUser}/>}
        <Router history={hist}>
            <Switch>
                <Route
                    path="/404"
                    component={() => (
                        <NotFound/>
                    )}
                />
                {!isLoggedIn() && !currentUser && <Route path="/" component={(props) => <AuthLayout {...props} />}/>}
                {isLoggedIn() && currentUser && <Route path="/"
                                                       component={(props) => <AdminLayout stateUpdates={stateUpdates}
                                                                                          currentUser={getCurrentUser} {...props} />}/>}
            </Switch>
        </Router>
    </MainContainer>
}

export default Application

