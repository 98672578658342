import React, {useState} from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const RenderDialog = ({
                          setOpenModal,
                          openModal,
                          classes,
                          modalBody,
                          modalSize,
                          fullWidth,
                          ...props
                      }) => {

    return <Dialog
        maxWidth={modalSize}
        fullWidth={fullWidth}
        classes={{
            root: classes.center + " " + classes.modalRoot,
        }}
        open={openModal}
        TransitionComponent={Transition}
        onClose={() => setOpenModal(false)}
    >
        <DialogContent>
            {modalBody}
        </DialogContent>
    </Dialog>
}

const Form = ({modalBody, modalButtonText, modalTittle, modalSize="xl", fullWidth=true, buttonProps = {}, ...props}) => {
    const [openModal, setOpenModal] = useState(false)

    const toggleModal = (toggle) => {
        setOpenModal(toggle)
    }

    return <>
        {openModal && <RenderDialog openModal={openModal} modalBody={modalBody}
                                    fullWidth={fullWidth}
                                    modalTittle={modalTittle}
                                    modalSize={modalSize}
                                    setOpenModal={toggleModal} {...props} />}


        <Button
            style={{float: "right"}}
            color="success"
            {...buttonProps}
            onClick={() => toggleModal(true)}
        >
            {modalButtonText}
        </Button>
    </>
}

export default withStyles(modalStyle)(Form);