import React, { Fragment } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { Api } from "PNH/services/Api";
import Button from "components/CustomButtons/Button";
import FormWrapper from "./FormWrapper";
import ShowActivity from "./Show/index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import ActivityCard from "./ActivityCard";
import CustomTabs from "components/CustomTabs/CustomTabs";
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: {},
      localization: {},
      data: [],
      total: "",
      edit: false,
      show: true,
      defaults: {},
      page: 0,
      future: true,
      fetch: true,
    };
  }

  render() {
    const { classes, activityID } = this.props;
    return (
      <>
        <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: <>Podsumowanie</>, tabIcon: SportsBasketballIcon, tabContent: <ShowActivity
                activityID={activityID}
                {...this.props}
              />
            },
            {
              tabName: <span
                onClick={() => {
                  window.history.pushState(
                    "",
                    "",
                    `/activities/${activityID}/edit`
                  );
                }}
              >Formularz</span>,
              tabIcon: SportsBasketballIcon,
              tabContent: <>
                <FormWrapper
                  activityID={activityID}
                  locationID={
                    this.state.localization &&
                    this.state.localization.id
                  }
                  defaults={this.state.defaults}
                  fetch={() => {
                    this.loadData();
                  }}
                  setNotifications={window.setNotifications}
                  data={this.props.data}
                  {...this.props}
                />
              </>
            },
            {
              tabName: <span onClick={() => {
                window.history.pushState(
                  "",
                  "",
                  `/activities/${activityID}/copy`
                );
              }}>Kopia</span>, tabIcon: SportsBasketballIcon, tabContent: <> <FormWrapper
                copy
                activityID={activityID}
                locationID={
                  this.state.localization &&
                  this.state.localization.id
                }
                defaults={this.state.defaults}
                fetch={() => {
                  this.loadData();
                }}
                setNotifications={window.setNotifications}
                data={this.props.data}
                {...this.props}
              />
              </>
            },
          ]}
        />
      </>
    );
  }
}

export default withStyles(dashboardStyle)(Profile);
