
import Accordion from "components/Accordion/Accordion";
import React from "react";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";

export const RowFunction = (row, formik) => {
    const {classes, values, setFieldValue} = formik;
    const params = row.params
    return [
        row.id,
        <div style={{minWidth: '200px'}}>{row.job_name}</div>, ,
        <div style={{maxWidth: '300px'}}>{row.job_status.substring(0, 200)}</div>,
        row.file && <a href={process.env.REACT_APP_API_URL + row.file}> Plik </a>,
        <div style={{maxWidth: '500px'}}><Accordion active={0} collapses={formatRow(row)}/></div>,
    ];
}

const formatRow = (row) => {
    return [
        {
            title: "Data",
            content: row.created_at,
        },
        {
            title: "Dodał",
            content: row.user.label,
        },
        {
            title: row.job_class.includes('Activities::') ? 'Zajęcia' : 'Dodatkowe informacje',
            content: row.job_class.includes('Activities::') ? formatActivities(row.info_box) : row.info_box,
        },

        {
            title: "Klasa",
            content: row.job_class,
        },
        {
            title: "Parametry",
            content: <pre>{JSON.stringify(row.params, null, 2)}</pre>,
        },
        {
            title: "Pełny status",
            content: row.job_status,
        },

    ]
}

const formatActivities = (info_box) => {
    if (!info_box) {
        return "-"
    }
    const info = info_box.split("<section>")

    return <>
        <GridContainer>
            <GridItem xs={12}>
                <h5> Dodano/Edytowano: {info[0].length == 0 && '-'}</h5>
                <ul style={{listStyleType: "decimal"}}>
                    {info[0].split(',').map((id) => {
                        return <li><a href={`${window.location.origin}/activities/${id}`}
                                      target="_blank">{`${window.location.origin}/activities/${id}`}</a></li>
                    })}
                </ul>
            </GridItem>
            <GridItem xs={12}>
                <h5> Błędy: {info[1].length == 0 && '-'}</h5>
                <ul style={{listStyleType: "decimal"}}>
                    {info[1].length > 0 && info[1].split('<error>').map((id) => {
                        return <li>{id}</li>
                    })}
                </ul>
            </GridItem>
        </GridContainer>
    </>
}