import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { I18n } from "PNH/components/I18n";
import Accordion from "components/Accordion/Accordion";

export const TableFilters = (formik) => {
    const { classes, SearchFunctions } = formik;
    return (<GridContainer>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <GridContainer style={{ justifyContent: 'flex-end' }}>
                            <GridItem xs={3}>
                                {withID(formik)}
                            </GridItem>
                            <GridItem xs={3}>
                                {withReportName(formik)}
                            </GridItem>

                            <GridItem xs={3}></GridItem>
                            <GridItem xs={3}>
                                {order(formik)}
                            </GridItem>

                            <GridItem xs={3} style={{ textAlign: "right" }}>
                                {SearchFunctions.showClearButton && <Button
                                    link
                                    color="success"
                                    onClick={SearchFunctions.clearFilters}
                                >
                                    Wyczyść
                                </Button>}
                                <Button
                                    color="success"
                                    onClick={formik.handleSubmit}
                                >
                                    Filtruj
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <input type="submit" style={{ display: 'none' }} />
        </form>
    </GridContainer>
    )
}

const withID = (formik) => (
    <CustomInput
        id={`with_id`}
        error={formik.errors.with_id}
        labelText="ID"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj...",
            type: "text",
            name: `with_id`,
            value: formik.values.with_id,
            onChange: (e) => { formik.setFieldValue(`with_id`, e.target.value) },
        }}
    />
)

const withReportName = (formik) => (
    <CustomInput
        id={`with_job_name`}
        error={formik.errors.with_job_name}
        labelText="Nazwa"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj...",
            type: "text",
            name: `with_job_name`,
            value: formik.values.with_job_name,
            onChange: (e) => { formik.setFieldValue(`with_job_name`, e.target.value) },
        }}
    />
)

const order = (formik) => {
    const { classes, setFieldValue, SearchFunctions } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Sortowanie
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.order}
            onChange={(e) =>
                setFieldValue(`order`, e.target.value)
            }
            inputProps={{
                isClearable: true,
                name: "order",
            }}
        >
            {SearchFunctions.orderBy().map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g)}
                </MenuItem>
            ))}

        </Select>
    </FormControl>
}

