import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";
import {ActivitiesForm} from "./ActivitiesFrom/index";
import {WsManager} from "PNH/services/Ws"
import {Api} from "../../../services/Api";

const CHANNEL_NAME = "Admin::ActivitiesChannel"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ActivitiesBulkUpdate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      statusModal: false,
    }
  }


  editModal = (open) => {
    this.setState({editModal: open});
  };
  statusModal = (open) => {
    this.setState({statusModal: open});

  };

  update = (data) => {
       Api.activities.admin
          .bulk_update({ids: this.props.ids, activity: data})
          .then((response) => {
            if (response.ok) {
              window.setNotifications([{ type: "success", msg: "Zlecono aktualizację" }]);
            } else {
              window.setNotifications([
                { type: "alert", msg: "Nie udało się zapisać zmian" },
              ]);
            }
          });
  }

  renderDialogStatus = () => {
    const {classes, afterSave, ...props} = this.props;
    return (
        <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal,
            }}
            open={this.state.statusModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.statusModal(false)}
        >
          <DialogTitle
              id="small-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
          >
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => this.statusModal(false)}
            >
              <Close className={classes.modalClose}/>
            </Button>
          </DialogTitle>
          <DialogContent
              id="small-modal-slide-description"
              className={classes.modalBody}
          >
            {this.state.statusMsg}
          </DialogContent>
        </Dialog>
    );
  };

  renderDialogEdit = () => {
    const {classes, afterSave, ...props} = this.props;
    return (
        <Dialog
            maxWidth={'lg'}
            open={this.state.editModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.editModal(false)}
        >
          <DialogTitle
              id="small-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
          >
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => this.editModal(false)}
            >
              <Close className={classes.modalClose}/>
            </Button>
          </DialogTitle>
          <DialogContent
              id="small-modal-slide-description"
              className={classes.modalBody}
          >
            <ActivitiesForm update={this.update} {...this.props} />
          </DialogContent>
        </Dialog>
    );
  };

  render() {
    return <>
      <Button
          color="primary"
          link

          onClick={() => {
            let allIds = this.props.values.data.rows.map((row) => row.id).concat(this.props.values.activitiesIDs)
            this.props.setFieldValue('activitiesIDs', allIds)
          }}
      >
        Zaznacz wszystkie na stronie
      </Button>
      <Button
          color="primary"
          link
          disabled={this.props.ids.length === 0}
          onClick={() => {
            const pageIds = this.props.values.data.rows.map((row) => row.id)
            let allIds = this.props.values.activitiesIDs.filter((row) => (
                !pageIds.includes(row)
            ))
            this.props.setFieldValue('activitiesIDs', allIds)
          }}
      >
        Odznacz wszystkie ze strony
      </Button>
      <Button
          color="primary"
          link
          disabled={this.props.ids.length === 0}
          onClick={() => this.props.setFieldValue('activitiesIDs', [])}
      >
        Odznacz wszystkie
      </Button>
      <Button
          color="primary"
          size="sm"
          disabled={this.props.ids.length === 0}
          onClick={() => this.editModal(true)}
      >
        Edytuj zaznaczone
      </Button>
      {this.state.editModal && this.renderDialogEdit()}
      {this.state.statusModal && this.renderDialogStatus()}
    </>;
  }
}

export default withStyles(modalStyle)(ActivitiesBulkUpdate);
